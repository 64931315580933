class color {
    color(){
        return ['primary', 'danger','secondary','success','info','warning','dark'];
    }
    btnOutline(){
        return ['btn-outline-primary', 'btn-outline-danger','btn-outline-secondary', 'btn-outline-success', 'btn-outline-info', 'btn-outline-warning' , 'btn-outline-dark']
    }
    badge(){
        return ['kt-badge--meta', 'kt-badge--primary', 'kt-badge--success', 'kt-badge--ínfo', 'kt-badge--warning', 'kt-badge--danger', 'kt-badge--dark', 'kt-badge--brand']
    }
}

export default new color();
