<template>
    <div class="row">
        <div class="col-2">
            <label for="">{{label}}</label>
        </div>
        <div class="col-10">
            <div class="form-group mb-2">
                <input
                    v-bind:value="value"
                    v-on:input="$emit('input', $event.target.value)"
                    class="form-control"
                    >
                   
            </div>
        </div>
    </div>
</template>

<script>
import DateRangePicker from "./../../_general/DateRangePicker.vue";

export default {
    components: {
        DateRangePicker
    },
    props: ['value'],
    props:{
        label: {
            type: String,
            default: 'name'
        },
    },
    data() {
        return{
            value: '',
        }
    },
}
</script>