<template>
    <!--begin::Row-->
    <div class="row">
        <div class="col-lg-4 col-xl-4 order-lg-1 order-xl-1">
            <!--begin::Portlet-->
            <div class="kt-portlet kt-portlet--fit kt-portlet--height-fluid">
            <div class="kt-portlet__body kt-portlet__body--fluid">
                <div class="kt-widget-3 kt-widget-3--brand">
                <div class="kt-widget-3__content">
                    <div class="kt-widget-3__content-info">
                    <div class="kt-widget-3__content-section">
                        <div class="kt-widget-3__content-title">Annual Profit</div>
                        <div class="kt-widget-3__content-desc">SAAS TOTAL PORIFT</div>
                    </div>
                    <div class="kt-widget-3__content-section">
                        <span class="kt-widget-3__content-bedge">$</span>
                        <span class="kt-widget-3__content-number">17<span>M</span></span>
                    </div>
                    </div>
                    <div class="kt-widget-3__content-stats">
                    <div class="kt-widget-3__content-progress">
                        <div class="progress">
                        <!-- Doc: A bootstrap progress bar can be used to show a user how far along it's in a process, see https://getbootstrap.com/docs/4.1/components/progress/ -->
                        <div
                            class="progress-bar bg-light"
                            style="width: 72%;"
                            role="progressbar"
                            aria-valuenow="25"
                            aria-valuemin="0"
                            aria-valuemax="100"
                        ></div>
                        </div>
                    </div>
                    <div class="kt-widget-3__content-action">
                        <div class="kt-widget-3__content-text">CHANGE</div>
                        <div class="kt-widget-3__content-value">+72%</div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <!--end::Portlet-->
        </div>
        <div class="col-lg-4 col-xl-4 order-lg-1 order-xl-1">
            <!--begin::Portlet-->
            <div class="kt-portlet kt-portlet--fit kt-portlet--height-fluid">
            <div class="kt-portlet__body kt-portlet__body--fluid">
                <div class="kt-widget-3 kt-widget-3--danger">
                <div class="kt-widget-3__content">
                    <div class="kt-widget-3__content-info">
                    <div class="kt-widget-3__content-section">
                        <div class="kt-widget-3__content-title">Annual Sales</div>
                        <div class="kt-widget-3__content-desc">TOTAL SALES AMOUNT</div>
                    </div>
                    <div class="kt-widget-3__content-section">
                        <span class="kt-widget-3__content-number">920<span>K</span></span>
                    </div>
                    </div>

                    <div class="kt-widget-3__content-stats">
                    <div class="kt-widget-3__content-progress">
                        <div class="progress">
                        <!-- Doc: A bootstrap progress bar can be used to show a user how far along it's in a process, see https://getbootstrap.com/docs/4.1/components/progress/ -->
                        <div
                            class="progress-bar bg-light"
                            style="width: 85%;"
                            role="progressbar"
                            aria-valuenow="25"
                            aria-valuemin="0"
                            aria-valuemax="100"
                        ></div>
                        </div>
                    </div>
                    <div class="kt-widget-3__content-action">
                        <div class="kt-widget-3__content-text">CHANGE</div>
                        <div class="kt-widget-3__content-value">+85%</div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <!--end::Portlet-->
        </div>
        <div class="col-lg-4 col-xl-4 order-lg-1 order-xl-1">
            <!--begin::Portlet-->
            <div class="kt-portlet kt-portlet--fit kt-portlet--height-fluid">
            <div class="kt-portlet__body kt-portlet__body--fluid">
                <div class="kt-widget-3 kt-widget-3--success">
                <div class="kt-widget-3__content">
                    <div class="kt-widget-3__content-info">
                    <div class="kt-widget-3__content-section">
                        <div class="kt-widget-3__content-title">Response Rate</div>
                        <div class="kt-widget-3__content-desc">Support Quality</div>
                    </div>
                    <div class="kt-widget-3__content-section">
                        <span class="kt-widget-3__content-number">89<span>%</span></span>
                    </div>
                    </div>

                    <div class="kt-widget-3__content-stats">
                    <div class="kt-widget-3__content-progress">
                        <div class="progress">
                        <!-- Doc: A bootstrap progress bar can be used to show a user how far along it's in a process, see https://getbootstrap.com/docs/4.1/components/progress/ -->
                        <div
                            class="progress-bar bg-light"
                            style="width: 45%;"
                            role="progressbar"
                            aria-valuenow="25"
                            aria-valuemin="0"
                            aria-valuemax="100"
                        ></div>
                        </div>
                    </div>
                    <div class="kt-widget-3__content-action">
                        <div class="kt-widget-3__content-text">CHANGE</div>
                        <div class="kt-widget-3__content-value">+45%</div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <!--end::Portlet-->
        </div>
    </div>
    <!--end::Row-->
</template>

<script>
    export default {

    }
</script>

<style>

</style>
