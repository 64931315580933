<template>
    <div class="row filter-search">
        <div class="col-2">
            <label for="">{{label}}</label>
        </div>
        <div class="col-10">
             <date-picker
                    :popup-class="'filter-input-search'"
                    :id="idDate"
                    v-model="res"
                    type="date"
                     format="YYYY-MM-DD"
                    :range="dateData == 'multiple' ? true : false"
                    :placeholder="dateData == 'multiple' ? 'DD/MM/YYYY - DD/MM/YYYY' : 'DD/MM/YYYY'"
                ></date-picker>
        </div>
    </div>
</template>

<script>
// import DateRangePicker from "./../../_general/DateRangePicker.vue";
// import DatePicker from "../../_general/DatePicker";
import DatePicker from 'vue2-datepicker';
export default {
    components: {
        DatePicker
    },
    props: ['value'],
    props:{
        label: {
            type: String,
            default: 'name'
        },
        idDate: {
            type: String,
            default: 'filterRange'
        },
        dateData: {
            type: String,
            default: 'multiple'
        },
        defaultDate: {
            type: Array,
            default: ()  => []
        }
    },
    data() {
        return {
            res: this.defaultDate
        }
    },
    watch: {
        res(val) { 
            if(this.defaultDate.length > 0 && !val[0] ) {
                this.res = this.defaultDate
            }
            this.$emit('input', this.res)
        },
        value(val) {
            this.res = val
            this.$emit('input', val)
        }
    }
}
</script>