<template>
  <div class="kt-spinner kt-spinner--sm kt-spinner--brand kt-spinner--right kt-spinner--input">
    <div
      class="dropdown bootstrap-select show-tick form-control"
      :class="{'is-invalid': errors.has('selectedYear') }"
    >
      <select
        @change="changedSelectPicker"
        class="form-control"
        :id="idSelectPicker"
        v-model="selected"
        :value="selectedValue"
        data-size="5"
        :disabled="disabledSelect"
        v-validate="isValidate ? 'required' : ''"
        data-vv-as="Tahun"
        :class="{'is-invalid': errors.has('selectedYear') }"
      >
        <option v-for="value in year" :value="value" :key="value">{{ value }}</option>
      </select>
    </div>
    <div
      v-show="errors.first('selectedYear')"
      class="invalid-feedback"
    >{{ errors.first('selectedYear') }}</div>
  </div>
</template>

<script>


export default {
  inject: ["$validator"],
  data() {
    return {
      dataResponse: null,
      selected: null,
      disabledSelect: true,
      year: []
    };
  },
  props: {
    selectedPicker: {},
    isValidate: { type: Boolean },
    idSelectPicker: {type: String, default:"selectYear"}
  },
  computed: {
    selectedValue() {
      this.selected = this.selectedPicker;
    }
  },
  methods: {
    getSelectPicker: function() {
      this.disabledSelect = false;
      $("#"+this.idSelectPicker).selectpicker();
      this.$nextTick(() => {
        $("#"+this.idSelectPicker).selectpicker("refresh");
      });
    },
    changedSelectPicker: function() {
      this.$emit("update:changedValue", this.selected);
    },
    GetOptionYear: function() {
        const date = new Date();
        const currentYear = date.getFullYear();
        for (let i = 0; i <= 10; i++) {
            this.year.push(currentYear - i);
        }
        this.getSelectPicker();
    }
  },
  mounted() {
      this.GetOptionYear();
  }
};
</script>
