<template>
<div class="card" >
    <div>
        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseSearch" aria-expanded="true" aria-controls="collapseSearch" id="btn-expand" @click="setCaret">
            <i class="fa fa-caret-square-up" v-if="isShowCaret"></i>
            <i class="fa fa-caret-square-down" v-else></i>
        </button>
      <i class="fa fa-search float-right mt-3 mr-2"></i>
    </div>
        
    <div id="collapseSearch" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
       <div class="row open m-2">
            <div class="container-fluid">
                <input-search :label="'Nama Pasien'" v-model="dataSearch.namaPasien"></input-search>
                <input-search :label="'Nama Dokter'" v-model="dataSearch.dokterNama"></input-search>
                <!-- <input-search :label="'Nama Klinik'" v-model="dataSearch.klinikNama"></input-search> -->
                <date-search :label="'Tanggal Lahir'" :id-date="'dateBirth'" :date-data="'single'" v-model="dataSearch.tanggalLahir" ></date-search>
                <input-search :label="'Telepon'" v-model="dataSearch.telepon"></input-search>
                <input-search :label="'Email'" v-model="dataSearch.email"></input-search>
                <input-search :label="'No. Registrasi'" v-model="dataSearch.nomorRegistrasi"></input-search>
                <input-search :label="'No. Rekam Medik'" v-model="dataSearch.nomorMedicalRecord"></input-search>
                <date-search :label="'Tanggal Dibuat Appointment'" :id-date="'CreatedDate'" v-model="dataSearch.createdDate"></date-search>
                <date-search :label="'Tanggal Kunjungan'" :id-date="'CreatedVisit'" :default-date="[new Date(), new Date()]" v-model="dataSearch.tanggalKunjungan"></date-search>
                <div class="row">
                    <div class="col-2">
                        <label for="">Status</label>
                    </div>
                    <div class="col-10">
                        <multi-select v-model="dataSearch.status" :options="options" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Status" :openDirection="'top'">
                            
                        </multi-select>
                    </div>
                </div>
                <button class="btn btn-primary" @click="searching(true)">
                    Search
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import InputSearch from "./InputSearch.vue";
import DateSearch from "./DateSearch.vue";
import MultiSelect from "vue-multiselect";
import SearchCallCenter from "./../../../model/search-call-center";

export default {
    components: {
        InputSearch,
        DateSearch,
        MultiSelect
    },
    data() {
        return {
            value2: [],
            isShowCaret: false,
            dataSearch: new SearchCallCenter(),
            options: ["NEW", "CONFIRM", "ARRIVED", "DISPATCH", "CANCEL"],
            params: {},
            defaultDateRange: [moment().format("YYYY-MM-DD")]
        };
    },
    async mounted() {
        try {
            const vx = this;
            this.$root.$on("searching", function() {
                vx.searching(false);
            });
            const { data } = await PrinterResourceRepository.getBackOfficeEnv();
            const defaultDays = data.defaultRangeDate
                ? data.defaultRangeDate
                : 0;
            if (this.fetchInvBooking) {
                this.dataSearch.status = ["CANCEL"];
                this.defaultDateRange.push(
                    moment()
                        .add(defaultDays, "days")
                        .format("DD-MM-YYYY")
                );
            } else {
                this.defaultDateRange.push(moment().format("YYYY-MM-DD"));
            }
        } catch (error) {
            console.log(error);
        }
    },
    // mounted() {
    //     const vx = this
    //     this.$root.$on('searching', function(){
    //         vx.searching(false)
    //     })
    // },
    methods: {
        searching(isLoad) {
            //
            const isShow = document
                .getElementById("collapseSearch")
                .classList.contains("show");
            let obj = {
                "namaPasien.contains": this.prefixPostfix(this.dataSearch.namaPasien),
                "dokterNama.contains": this.prefixPostfix(this.dataSearch.dokterNama),
                // "klinikNama.contains": this.prefixPostfix(this.dataSearch.klinikNama),
                // "klinikNama.contains": this.prefixPostfix(this.dataSearch.klinikNama),
                "tanggalLahir.equals": this.setFormatFromLocaleTime(this.dataSearch.tanggalLahir),
                "telpon.contains": this.prefixPostfix(this.checkNumber(this.dataSearch.telepon)),
                "email.contains": this.prefixPostfix(this.dataSearch.email),
                "nomorRegistrasi.contains": this.prefixPostfix(
                    this.dataSearch.nomorRegistrasi
                ),
                "nomorMedicalRecord.contains": this.prefixPostfix(
                    this.dataSearch.nomorMedicalRecord
                ),
                "createdDate.greaterOrEqualThan": this.cekArrayIn(
                    this.dataSearch.createdDate,
                    0
                )
                    ? `${this.setFormatFromLocaleTime(
                          this.dataSearch.createdDate[0]
                      )}T00:00:00Z`
                    : "",
                "createdDate.lessOrEqualThan": this.cekArrayIn(
                    this.dataSearch.createdDate,
                    1
                )
                    ? `${this.setFormatFromLocaleTime(
                          this.dataSearch.createdDate[1]
                      )}T23:59:00Z`
                    : "",
                ...(this.cekArrayIn(this.dataSearch.tanggalKunjungan, 0)
                    ? {
                          "tanggalKunjungan.greaterOrEqualThan": `${this.setFormatFromLocaleTime(
                              this.cekArrayIn(
                                  this.dataSearch.tanggalKunjungan,
                                  0
                              )
                          )}`
                      }
                    : {}),
                ...(this.cekArrayIn(this.dataSearch.tanggalKunjungan, 1)
                    ? {
                          "tanggalKunjungan.lessOrEqualThan": `${this.setFormatFromLocaleTime(
                              this.cekArrayIn(
                                  this.dataSearch.tanggalKunjungan,
                                  1
                              )
                          )}`
                      }
                    : {}),
                "status.in": this.dataSearch.status.toString()
            };
            if (isShow)
                this.$store.commit(
                    "DoctorAppointmentStore/SET_FILTER_SEARCH_PARAMS",
                    obj
                );
            else
                this.$store.commit(
                    "DoctorAppointmentStore/SET_FILTER_SEARCH_PARAMS",
                    {}
                );
            if (isLoad)
                this.$store.dispatch(
                    "DoctorAppointmentStore/GET_LIST_CALL_CENTER_BOOKING"
                );
        },
        prefixPostfix(val) {
            if (val) return `%${val.toUpperCase()}%`;
            return val;
        },
        checkNumber(val) {
            if (val) {
                if (val.length >= 6) {
                    if (val[0] == "0" || val[0] == 0) val = val.slice(1);
                    return val;
                }
                return val;
            }
            return "";
        },
        search() {
            $("#btn-expand").click();
        },
        setCaret() {
            this.isShowCaret = !this.isShowCaret;
        },
        cekArrayIn(date, arrayIn) {
            if (date.length > 0) {
                if (date[0]) {
                    return date[arrayIn];
                }
            }
            return "";
        },
        setFormatFromLocaleTime(time) {
            if (!time) return "";
            const dateFormat = new Date(time);
            const timeLocale = dateFormat.toLocaleString("id-ID");
            return moment(timeLocale, "DD/MM/YYYY").format("YYYY-MM-DD");
        }
    }
};
</script>
