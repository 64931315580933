<template>
  <div>
    <base-header :base-title="$t('module.dashboard')"></base-header>
    <div
      class="kt-content kt-grid__item kt-grid__item--fluid"
      id="kt_content"
    >
      <!-- <kanban></kanban> -->
       <div class="row">
            <div :class="column" v-if="checkPermission('ReservasiResource.GET.Reservasi')">
                <count-reservasi></count-reservasi>
            </div>
            <div :class="column" v-if="checkPermission('ReportResource.GET.Report.TransaksiReferralTopTotalRujukan')">
                <top-rujukan></top-rujukan>
            </div>
        </div>
        <div class="row">
            <div class="col-12" v-if="checkPermission('ReportResource.GET.Report.QueueEpisodeCount')">
                <count-queue></count-queue>
            </div>
        </div>

        <!-- Loyalty -->
        <div class="row">
            <div class="col-12" v-if="checkPermission('LoyaltyDashboardResource.GET.memberRegistrationTrend')">
                <member-registration />
            </div>
        </div>
        <div class="row">
            <div class="col-12" v-if="checkPermission('LoyaltyDashboardResource.GET.couponUsedTrend')">
                <coupon-used />
            </div>
        </div>
        <div class="row">
            <div class="col-12" v-if="checkPermission('LoyaltyDashboardResource.GET.claimSummaryAmount')">
                <summary-claim-amount />
            </div>
        </div>
        <div class="row">
            <div class="col-12" v-if="checkPermission('LoyaltyDashboardResource.GET.claimSummaryPoint')">
                <summary-claim-point />
            </div>
        </div>
        <div class="row">
            <div class="col-12" v-if="checkPermission('LoyaltyDashboardResource.GET.activityCustomPoint')">
                <custom-point />
            </div>
        </div>
        <div class="row">
            <div class="col-12" v-if="checkPermission('LoyaltyDashboardResource.GET.activityMember')">
                <activity-member />
            </div>
        </div>
        <div class="row">
            <div class="col-12" v-if="checkPermission('LoyaltyDashboardResource.GET.historyTierMember')">
                <history-tier-member />
            </div>
        </div>

    </div>
  </div>
</template>

<script>
import BaseHeader from "./../_base/BaseHeader";
import Kanban from "./_components/Kanban";
import CountQueue from "./_components/CountQueue";
import TopRujukan from "./_components/TopRujukan";
import CountReservasi from "./_components/CountReservasi";

// loyalty
import MemberRegistration from "./../../loyalty/components/dashboard/MemberRegistration";
import CouponUsed from "./../../loyalty/components/dashboard/CouponUsed";
import SummaryClaimAmount from "./../../loyalty/components/dashboard/SummaryClaimAmount";
import SummaryClaimPoint from "./../../loyalty/components/dashboard/SummaryClaimPoint";
import CustomPoint from "./../../loyalty/components/dashboard/CustomPoint";
import ActivityMember from "./../../loyalty/components/dashboard/ActivityMember";
import HistoryTierMember from "./../../loyalty/components/dashboard/HistoryTierMember";

export default {
  components: {
    BaseHeader,
    Kanban,
    CountQueue,
    TopRujukan,
    CountReservasi,

    MemberRegistration,
    CouponUsed,
    SummaryClaimAmount,
    SummaryClaimPoint,
    CustomPoint,
    ActivityMember,
    HistoryTierMember
  },
  mounted() {

  },
  methods: {
    checkPermission: function (method) {
      return helpers.isHavePermission(method);
    }
  },
  computed: {
      column() {
        const total = 12;
        let val = 0;
        val = (this.checkPermission('ReportResource.GET.Report.TransaksiReferralTopTotalRujukan')) ? val + 1 : val;
        val = (this.checkPermission('ReservasiResource.GET.Reservasi')) ? val + 1 : val;
        val = (val == 0) ? 1 : val;
        const use = total / val;
        return `col-md-12 col-lg-${use} col-xl-${use}`
      }
  },
};
</script>
