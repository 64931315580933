<template>
    <div class="kt-portlet kt-portlet--height-fluid">
        <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title">Tren Penggunaan Kupon</h3>
            </div>
            <div class="kt-portlet__head-toolbar">
                <div class="pull-right">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Periode</span>
                        </div>
                        <select
                            @change="onChangePeriode"
                            class="form-control"
                            id="periode-select"
                            v-model="periodeSelected"
                            :value="periodeSelectedValue"
                            name="periode">
                            <option value="1">Yearly</option>
                            <option value="2">Monthly</option>
                            <option value="3">Daily</option>
                        </select>
                    </div>

                </div>
            </div>
        </div>
        <div class="kt-portlet__body">
            <div class="row mb-3">
                <div v-if="periodeSelected >= 2" class="col-6">
                    <year-select
                        :changedValue.sync="year"
                        :id-select-picker="'selectYearCoupon'"
                        :selected-picker="year"
                        :is-validate="true"
                    ></year-select>
                </div>
                <div v-if="periodeSelected == 3" class="col-6">
                    <month-select
                        :changedValue.sync="month"
                        :id-select-picker="'selectMonthCoupon'"
                        :selected-picker="month"
                        :value-num="true"
                    ></month-select>
                </div>
            </div>

            <div class="kt-widget-4 ps-scroll2 kt-scroll" data-scroll="true"  style="height:400px">
                <div v-if="!isShowCanvas" class="d-flex justify-content-center">
                    <div v-if="!isLoading">
                        <div class="kt-iconbox">
                            <div class="kt-iconbox__icon">
                                <div class="kt-iconbox__icon-bg"></div>
                                <i class="flaticon-graph"></i>
                            </div>
                            <div class="kt-iconbox__title">
                                Data tidak ditemukan.
                            </div>
                        </div>
                    </div>
                    <div class="text-center" v-else>
                        <div class="spinner-border">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
                <canvas
                    id="coupon_bar_chart"
                    style="width: 100% !important;height: auto !important;"
                />
            </div>
        </div>
    </div>
</template>

<script>
import MonthSelect from "./../../../components/_select/Month";
// import YearSelect from "./../../../components/_select/Year";
import YearSelect from "./../base/YearSelect";
import Pallate from "./../../../constants/color-palate";
import { RepositoryFactory } from "./../../repositories/BaseRepositoryFactory";
const DashboardRepository = RepositoryFactory.get("dashboardRepository");
var parse = require('parse-link-header');

export default {
    components: {
        MonthSelect,
        YearSelect
    },
    data() {
        return {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            periodeSelected: 3,
            periodeSelectedValue: 3,
            data : [],
            isShowCanvas: false,
            isLoading: true,
            // colorBadge: Pallate.badge(),
            graphPallate: Pallate.color(),
            myChart : null,
        }
    },
    watch: {
        year(val){
            this.refreshData();
        },
        month(val){
            this.refreshData();
        }
    },
    methods: {
        convertSeperator: function(angka) {
            if (angka !== null) {
                const parts = angka.toString().split('.');
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                return parts.join(',');
            }
            return angka;
        },
        refreshData: function(){
            this.isShowCanvas = false;
            this.isLoading = true;
            this.data = [];
            this.getData();
        },
        getData: function(){
            var vx = this;
            var params = {
                period: this.periodeSelected,
                year: this.year,
                month: this.month
            };
            new Promise((resolve, reject) => {
                DashboardRepository.couponUsed(params, resolve, reject );
            })
            .then(response => {
                vx.isLoading = false;
                vx.data = response.data;
                if(vx.data.length > 0){
                    vx.isShowCanvas = true;
                    vx.renderChart(response.data);
                }
            }).catch(error => {
                var msg = (error.message) ? error.message : error;
                toastr.error('Bar Chart: ' + msg);
            })
        },
        renderChart: function(data){
            var vx = this;
            var ctx = document.getElementById('coupon_bar_chart').getContext('2d');
            var listLabel = [];
            var datasets = [];
            for(var ix in data) {
                listLabel.push(data[ix].x);
                datasets.push(data[ix].y);
            }
            var labelString = '';
            switch(vx.periodeSelected){
                case 1:
                    labelString = 'Tahun';
                    break;
                case 2:
                    labelString = 'Bulan';
                    break;
                case 3:
                    labelString = 'Tanggal';
                    break;
            }

            var useMax = Math.max(...datasets);
            useMax = (useMax < 10) ? 10 : useMax;

            if(this.myChart){
                this.myChart.destroy();
            }
            this.myChart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: listLabel,
                    datasets: [{
                        data: datasets,
                        backgroundColor: Chart.helpers.color(KTApp.getStateColor(vx.graphPallate[0])).alpha(0.3).rgbString(),
                        pointBackgroundColor: KTApp.getStateColor(vx.graphPallate[0]),
                        borderWidth: 1
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: false,
                    scales: {
                        xAxes: [{
                                display: true,
                                scaleLabel: {
                                display: false,
                                labelString: labelString
                            },
                            gridLines: false,
                            ticks: {
                                display: true,
                                beginAtZero: true,
                                fontColor: KTApp.getBaseColor('shape', 3),
                                fontSize: 13,
                                padding: 10,
                                callback:  (value) => {
                                    if(vx.periodeSelected == 3){
                                        return moment(value).format('DD MMM')
                                    }
                                    return value;
                                }
                            }
                        }],
                        yAxes: [{
                            display: true,
                            scaleLabel: {
                                display: false,
                                labelString: 'Jumlah'
                            },
                            gridLines: {
                                color: '#eef2f9',
                                drawBorder: false,
                                // offsetGridLines: true,
                                drawTicks: false,
                            },
                            ticks: {
                                max: useMax,
                                stepSize: useMax / 10,
                                display: true,
                                beginAtZero: true,
                                fontColor: KTApp.getBaseColor('shape', 3),
                                fontSize: 13,
                                padding: 10,
                                callback:  (value) => {
                                    return vx.convertSeperator(value);
                                }
                            }
                        }]
                    }
                }
            });
        },
        onChangePeriode: function() {
            this.periodeSelectedValue = this.periodeSelected;
            this.refreshData();
        }
    },
    mounted () {
        // var vx = this;
        // var container = document.querySelector('.ps-scroll2');
        // const ps1 = new PerfectScrollbar(container, {minScrollbarLength: false});
        this.refreshData();
    },
}
</script>

<style>

</style>
