<template>
  <div class="kt-spinner kt-spinner--sm kt-spinner--brand kt-spinner--right kt-spinner--input">
    <div
      class="dropdown bootstrap-select show-tick form-control"
      :class="{'is-invalid': errors.has('selectedMonth') }"
    >
      <select
        @change="changedSelectPicker"
        class="form-control"
        :id="idSelectPicker"
        v-model="selected"
        :value="selectedValue"
        data-size="5"
        :disabled="disabledSelect"
        v-validate="isValidate ? 'required' : ''"
        name="selectedMonth"
        data-vv-as="Bulan"
        :class="{'is-invalid': errors.has('selectedMonth') }"
      >
        <option v-for="value in month" :value="(valueNum) ? value.num : value" :key="value.num">{{ value.text }}</option>
      </select>
    </div>
    <div
      v-show="errors.first('selectedMonth')"
      class="invalid-feedback"
    >{{ errors.first('selectedMonth') }}</div>
  </div>
</template>

<script>


export default {
  inject: ["$validator"],
  data() {
    return {
      dataResponse: null,
      selected: null,
      disabledSelect: true,
      month: [
          {text: 'January' , num: 1},
          {text: 'February', num: 2},
          {text: 'March', num: 3},
          {text: 'April', num: 4},
          {text: 'May', num: 5},
          {text: 'June', num: 6},
          {text: 'July', num: 7},
          {text: 'August', num: 8},
          {text: 'September', num: 9},
          {text: 'October', num: 10},
          {text: 'November', num: 11},
          {text: 'December', num: 12}
        ]
    };
  },
  props: {
    selectedPicker: {},
    isValidate: { type: Boolean },
    valueNum: {type: Boolean , default: false},
    idSelectPicker: {type: String, default:"selectMonth"}
  },
  computed: {
    selectedValue() {
      this.selected = this.selectedPicker;
    }
  },
  methods: {
    getSelectPicker: function() {
      this.disabledSelect = false;
      $("#"+this.idSelectPicker).selectpicker();
      this.$nextTick(() => {
        $("#"+this.idSelectPicker).selectpicker("refresh");
      });
    },
    changedSelectPicker: function() {
      this.$emit("update:changedValue", this.selected);
    }
  },
  mounted() {
    this.getSelectPicker();
  }
};
</script>
