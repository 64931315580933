<template>
    <div
        class="card mb-3 shadow-sm bg-card"
        @click="setData"
        :id="dataBooking.id"
    >
        <div class="card-body p-0">
            <div class="row p-0">
                <div class="col-md-10 col-sm-12 ">
                    <div class="row p-3">
                        <div class=" col-md-4 col-sm-12 p-3 border-right">
                            <h5 class="m-0">
                                Booking
                                <small
                                    class="text-danger"
                                    v-if="dataBooking.defect"
                                    >* defect</small
                                >
                                <span>#{{ dataBooking.id }}</span>
                            </h5>
                            <p
                                v-b-tooltip.hover.leftTop
                                :title="dataBooking.pesan"
                                class="text-elypsis"
                            >
                                {{ dataBooking.pesan }}
                                <span v-if="dataBooking.statusInv"
                                    >| {{ dataBooking.statusInv }}</span
                                >
                            </p>
                            <h6 class="m-0">Tanggal Kunjungan :</h6>
                            <p>
                                {{ dataBooking.tanggalKunjungan | formatDate }}
                                ({{
                                    dataBooking.queue && 
                                    dataBooking.queue.slotJadwal
                                        ? dataBooking.queue.slotJadwal.jamMulai
                                        : "-"
                                }})
                            </p>
                            <h6 class="m-0">Tanggal Dibuat :</h6>
                            <p>{{ dataBooking.createdDateTime | formatUtc }}</p>
                        </div>
                        <div class=" col-md-4 col-sm-12 p-3 border-right">
                            <h5>Dokter</h5>
                            <p>
                                {{
                                    dataBooking.jadwal &&
                                    dataBooking.jadwal.dokter
                                        ? dataBooking.jadwal.dokter.nama
                                        : "-"
                                }}
                            </p>
                            <p>
                                <!-- {{
                                    dataBooking.jadwal &&
                                    dataBooking.jadwal.dokter
                                        ? dataBooking.jadwal.dokter
                                              .tujuanRujukan
                                            ? dataBooking.jadwal.dokter
                                                  .tujuanRujukan.nama
                                            : "-"
                                        : "-"
                                }} -->
                                {{dataBooking.jadwal.dokter.dokterSpesialisasis|defaultSpesialisasi}}
                            </p>
                        </div>
                        <div class=" col-md-4 col-sm-12 p-3 ">
                            <h5>Pasien <span v-if="dataBooking.nomorMedicalRecord" class="text-MRN">-</span> {{dataBooking.nomorMedicalRecord ? ` ${dataBooking.nomorMedicalRecord} `: ''}}</h5>
                            <p class="mb-0">{{ dataBooking.namaPasien }}</p>
                            <template v-if="isPermissionWhatsapp">
                                <p
                                    title="send to WA"
                                    class=" mb-2"
                                >
                                <span @click="WAme(dataBooking.telp, dataBooking.id)" class=" show-bigger"> {{ dataBooking.telp }}</span>
                                   
                                </p>
                            </template>
                            <template v-else>
                                <p>{{ dataBooking.telp }}</p>
                            </template>
                            <p>{{ dataBooking.tanggalLahir | formatBirth }}</p>
                            <p>({{ dataBooking.tanggalLahir | getDate }})</p>
                        </div>
                    </div>
                </div>
                <div
                    class="col-md-2 col-sm-12  bg-icon m-0 p-0 text-center position-relative"
                    :class="dataBooking.classCancel ?
                             dataBooking.classCancel :
                             (dataBooking.statusInv ? 'invalid-card' : '' )"
                >
                    <table class="position-up ">
                        <tr>
                            <td
                                class="p-2"
                                style="border-right: 2px solid white; border-bottom: 1.5px solid white;"
                            >
                                <span>
                                    <i
                                        class="fa  fa-headset text-white"
                                        v-if="dataBooking.teleconsultPayment"
                                    ></i>
                                    <i class="fa fa-user text-white" v-else></i>
                                </span>
                            </td>
                            <td
                                class="p-2"
                                style="border-bottom: 1.5px solid white;"
                            >
                                <span v-if="dataBooking.teleconsultPayment">
                                    <i
                                        class="fa  fa-dollar-sign text-white"
                                        v-if="
                                            dataBooking.teleconsultPayment
                                                .status == 'PAID'
                                        "
                                    ></i>
                                    <span
                                        class="strikethrough"
                                        v-if="
                                            dataBooking.teleconsultPayment
                                                .status == 'UNDERPAYMENT' &&
                                                !dataBooking.teleconsultPayment
                                                    .isTimeExpired
                                        "
                                    >
                                        <i
                                            class="fa  fa-dollar-sign text-danger"
                                        ></i>
                                    </span>
                                    <i
                                        class="fas fa-hourglass-end text-white"
                                        v-if="
                                            dataBooking.teleconsultPayment
                                                .status == 'UNDERPAYMENT' &&
                                                dataBooking.teleconsultPayment
                                                    .isTimeExpired
                                        "
                                    ></i>
                                </span>
                                <span v-else class="not-avalailable">
                                    -
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td
                                class="p-2"
                                style="border-right: 2px solid white;"
                            >
                                <span>
                                    <i
                                        class="fa fa-credit-card text-white"
                                        v-if="
                                            dataBooking.createdVia ==
                                                'BACKOFFICE'
                                        "
                                    ></i>
                                    <i
                                        class="fa  fa-mobile-alt text-white"
                                        v-else
                                    ></i>
                                </span>
                            </td>
                            <td class="p-2">
                                <span>
                                    <i
                                        class="fa fa-check-square text-white"
                                        v-if="dataBooking.nomorRegistrasi"
                                    ></i>
                                    <i
                                        :class="
                                            this.dataBooking.statusInv
                                                ? 'far fa-square text-primary'
                                                : 'far fa-square text-danger'
                                        "
                                        v-else
                                    ></i>
                                </span>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    computed: {
        ...mapState("DoctorAppointmentStore", {
            isPermissionWhatsapp: state => state.isPermissionWhatsapp,
            invBooking: state => state.fetchInvBooking
        })
    },

    props: {
        dataBooking: {
            type: Object,
            default: () => {
                return {
                    nama: "",
                    tanggalLahir: "",
                    tanggalKunjungan: "",
                    jam: "",
                    dokterNama: "",
                    jadwal: ""
                };
            }
        }
    },
    filters: {
        formatDate: function(value) {
            return moment(value).format("dddd, DD MMM YYYY");
        },
        formatUtc: function(value) {
            return moment.utc(value).format("dddd, DD MMM YYYY (HH:mm)");
        },
        getDate: function(value) {
            var months = moment().diff(value, "months");
            return `${Math.floor(months / 12)} tahun ${months % 12} bulan`;
        },
        formatBirth: function(value) {
            return moment(value).format("DD MMM YYYY");
        },
        defaultSpesialisasi(array){
            let defaultSpesialisasi 
            if (array) {
                array.forEach(item => {
                    // console.log(item,'itemmss');
                    if (item.default) {
                        defaultSpesialisasi = item
                    }
                })
                // console.log(defaultSpesialisasi , "defaultSpesialisasi");
                if (defaultSpesialisasi) {
                    if (defaultSpesialisasi.spesialisasi) {
                        
                        return defaultSpesialisasi.spesialisasi.namaIndo
                    }
                }
            }
        }
    },
    methods: {
        ...mapActions({
            setSelectedPanel: "DoctorAppointmentStore/SET_SELECTED_PANEL"
        }),
        setData(event) {
            if (this.$store.state.DoctorAppointmentStore.isLoadedBtn) {
                toastr.info("harap tunggu data");
                return false;
            }
            this.setSelectedPanel(this.dataBooking);
            const card = document.getElementsByClassName("bg-card");
            for (let i = 0; i < card.length; i++) {
                if (card[i].classList.contains("clickCard"))
                    card[i].classList.remove("clickCard");
            }
            document
                .getElementById(this.dataBooking.id)
                .classList.add("clickCard");
        },
        WAme(numberWA, idReservasi) {
            this.$store.commit("DayCalendarStore/SET_SHOW_MODAL_WA", true);
            this.$store.commit("DayCalendarStore/SET_NUMBER_WA", numberWA);
            this.$store.commit("DayCalendarStore/SET_RESERVASI_ID", idReservasi);
        }
    }
};
</script>
