<template>
    <div class="row">
        <div class="col-12" id="infinite-list">
            <card-call-center
                v-for="(item, i) in changeData(
                    listCallCenterBooking,
                    invalidBooking
                )"
                :key="i"
                :data-booking="item"
            ></card-call-center>
        </div>

        <div class="col-md-12 text-center p-3" v-if="loadMore">
            <div class="text-primary" role="status">
                <span class="spinner-grow"></span>
                <p>Loading...</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import CardCallCenter from "./CardCallCenter.vue";
export default {
    data() {
        return {
            loadMore: false,
            drr: [],
            timeDebounce: ""
        };
    },
    components: {
        CardCallCenter
    },
    computed: {
        ...mapState("DoctorAppointmentStore", {
            listCallCenterBooking: state => state.listCallCenterBooking,
            filterSearchParams: state => state.filterSearchParams,
            totalPageCallCenter: state => state.totalPageCallCenter,
            callCenterPage: state => state.callCenterPage,
            invalidBooking: state => state.invalidBooking
        })
    },
    methods: {
        async fetch() {
            // page = 10
            const vx = this;
            let maxPage = Math.ceil(this.totalPageCallCenter / 10);
            if (this.callCenterPage < maxPage) {
                // vx.loadMore = true;
                await this.$store.dispatch(
                    "DoctorAppointmentStore/GET_LIST_CALL_CENTER_BOOKING",
                    "plus"
                );
                // setTimeout(() => {
                // vx.fetch();
                    // this.loadMore = false;
                // }, 1000);
            }
        },
        changeData(data, type) {
            if (type === "All") {
                return data;
            } else if (type == "Invalid") {
                let dataFilter = data.filter(item => item.statusInv);
                // console.log(dataFilter, "dataFilterInvalid");
                return dataFilter;
            } else if (type == "Valid") {
                let dataFilter = data.filter(item => !item.statusInv);
                // console.log(dataFilter, "dataFilterValid");
                return dataFilter;
            }
        }
    },
    mounted() {
        // let vx = this;
        // vx.fetch();
        // $(window).scroll(function() {
        //     let tinggi = Math.floor($(window).scrollTop() + $(window).height());
        //     if (
        //         (tinggi >= $(document).height() ||
        //             tinggi == $(document).height() - 1) &&
        //         !vx.loadMore
        //     ) {
        //         if (this.timeDebounce) clearTimeout(this.timeDebounce);
        //         this.timeDebounce = setTimeout(() => {
        //             vx.fetch();
        //         }, 100);
        //         // vx.loadMore = true;
        //     }
        // });
    }
};
</script>

<style></style>
