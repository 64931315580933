export default function SearchCallCenter() {
    return {
       dokterNama: '',
       klinikNama: '',
       namaPasien: '',
       tanggalLahir: '',
       telepon: '',
       email: '',
       nomorRegistrasi: '',
       nomorMedicalRecord: '',
       createdDate: [],
       tanggalKunjungan: [],
       status: ['CONFIRM'],
       typeReservasi: [],
       statusBayar: [],
       hisStatus: [],
       createdVia: []
    };
}
