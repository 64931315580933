<template>
    <div class="row">
        <div class="col-lg-12">
            <!--begin::Portlet-->
            <div class="kt-portlet kt-portlet--height-fluid">
            <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title">Jumlah Antrian</h3>
                </div>
                <div class="kt-portlet__head-toolbar">
                <div class="kt-portlet__head-wrapper">
                    <!-- <div class="dropdown dropdown-inline">
                        <button
                            type="button"
                            class="btn btn-label-brand btn-sm btn-bold dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            Export
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                            <ul class="kt-nav">
                            <li class="kt-nav__section kt-nav__section--first">
                                <span class="kt-nav__section-text">Export Tools</span>
                            </li>
                            <li class="kt-nav__item">
                                <a
                                href="#"
                                class="kt-nav__link"
                                >
                                <i class="kt-nav__link-icon la la-print"></i>
                                <span class="kt-nav__link-text">Print</span>
                                </a>
                            </li>
                            <li class="kt-nav__item">
                                <a
                                href="#"
                                class="kt-nav__link"
                                >
                                <i class="kt-nav__link-icon la la-copy"></i>
                                <span class="kt-nav__link-text">Copy</span>
                                </a>
                            </li>
                            <li class="kt-nav__item">
                                <a
                                href="#"
                                class="kt-nav__link"
                                >
                                <i class="kt-nav__link-icon la la-file-excel-o"></i>
                                <span class="kt-nav__link-text">Excel</span>
                                </a>
                            </li>
                            <li class="kt-nav__item">
                                <a
                                href="#"
                                class="kt-nav__link"
                                >
                                <i class="kt-nav__link-icon la la-file-text-o"></i>
                                <span class="kt-nav__link-text">CSV</span>
                                </a>
                            </li>
                            <li class="kt-nav__item">
                                <a
                                href="#"
                                class="kt-nav__link"
                                >
                                <i class="kt-nav__link-icon la la-file-pdf-o"></i>
                                <span class="kt-nav__link-text">PDF</span>
                                </a>
                            </li>
                            </ul>
                        </div>
                    </div> -->
                </div>
                </div>
            </div>
            <div class="kt-portlet__body kt-portlet__body--fluid">
                <div class="kt-widget-9">
                <div class="kt-widget-9__panel row">
                    <div class="kt-widget-9__legends col-6">
                        <template v-if="showLegend">
                        <label class="btn btn-elevate btn-pill btn-sm mr-2"
                             v-for="(value,name, index) in show" :key="name"
                             :class="[btnPallate[index], {active : show[name] } ]"
                         >
                            <input
                                style="display:none;"
                                v-model="show[name]"
                                @change="initAll"
                                type="checkbox"
                            /> {{ $t('EnumStage.'+name )}}
                        </label>
                        </template>
                    </div>
                    <div class="kt-widget-9__toolbar col-6">
                    <div class="row">
                        <div class="col-6">
                            <year-select
                                :changedValue.sync="year"
                                :id-select-picker="'selectYear2'"
                                :selected-picker="year"
                                :is-validate="true"
                            ></year-select>
                        </div>
                        <div class="col-6">
                            <month-select
                                :changedValue.sync="month"
                                :id-select-picker="'selectMonth2'"
                                :selected-picker="month"
                                :value-num="true"
                            ></month-select>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="kt-widget-9__chart">
                    <canvas
                        v-if="showCanvas"
                        id="kt_bar_chart"
                        height="300"
                    ></canvas>
                    <div v-else class="d-flex justify-content-center">
                        <div v-if="!isAllTrue">
                            <div class="kt-iconbox">
                                <div class="kt-iconbox__icon">
                                    <div class="kt-iconbox__icon-bg"></div>
                                    <i class="flaticon-graph"></i>
                                </div>
                                <div class="kt-iconbox__title" v-if="data.length > 0">
                                    Silahkan pilih option grafik diatas.
                                </div>
                                <div class="kt-iconbox__title" v-else>
                                    Data grafik tidak ditemukan.
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <!--end::Portlet-->
        </div>
    </div>
</template>

<script>
    import MonthSelect from "./../../_select/Month";
    import YearSelect from "./../../_select/Year";
    import Pallate from "./../../../constants/color-palate"
    import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
    const ReportRepository = RepositoryFactory.get("report");
    export default {
        data() {
            return {
                year: new Date().getFullYear(),
                month: new Date().getMonth() + 1,
                data: [],
                showCanvas: false,
                show: {},
                myBar: null,
                showLegend: false,
                btnPallate: Pallate.btnOutline(),
                graphPallate: Pallate.color(),
                isAllTrue: true
            }
        },
        components: {
            MonthSelect,
            YearSelect
        },
        watch: {
            year(val){
                this.getData();
            },
            month(val){
                this.getData();
            }
        },
        methods: {

            convertSeperator: function(angka) {
                if (angka !== null) {
                    const parts = angka.toString().split('.');
                    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                    return parts.join(',');
                }
                return angka;
            },
            getData: function(){
                this.isAllTrue = true;
                this.showCanvas = false;
                this.showLegend = false;
                var startDate = moment(this.year + '-' + this.month + '-01').format('YYYY-MM-DD');
                var endDate = moment(startDate).endOf('month').format('YYYY-MM-DD');
                var params = {
                    startDate: startDate,
                    endDate: endDate
                };
                new Promise((resolve, reject) => {
                    ReportRepository.reportQueueCount(params, resolve, reject );
                })
                .then(response => {
                    this.data = response.data;
                    if(this.data.length > 0){
                        Object.keys(this.data[0].value).forEach(value => {
                            this.show[value] = true
                        });
                        this.showLegend = true;
                        this.initAll();
                    } else {
                        this.isAllTrue = false;
                    }
                }).catch(error => {
                    var msg = (error.message) ? error.message : error;
                    toastr.error('Bar Chart: ' + msg);
                })
            },
            initAll: function(){
                this.showCanvas = false;
                var vx = this;
                var listDataset = [];
                var listMax = [];
                var useMax = 0;
                this.isAllTrue = false;
                Object.keys(this.data[0].value).forEach((value , index) => {
                    if(this.show[value]){
                        this.isAllTrue = true;
                        var listValue = this.data.map(x => x.value[value]);
                        var max = Math.ceil(Math.max(...listValue));
                        var dataSet =  {
                            label: value,
                            borderColor: KTApp.getStateColor(vx.graphPallate[index]),
                            borderWidth: 2,
                            data: listValue,
                            backgroundColor: Chart.helpers.color(KTApp.getStateColor(vx.graphPallate[index])).alpha(0.3).rgbString(),
                            pointBackgroundColor: KTApp.getStateColor(vx.graphPallate[index]),
                        };
                        listDataset.push(dataSet);
                        listMax.push(max);
                    } else {
                        this.isAllTrue = false;
                    }
                });
                useMax = Math.max(...listMax);
                useMax = (useMax < 10) ? 10 : useMax;
                if(listDataset.length > 0){
                    this.showCanvas = true;
                    setTimeout(() => {
                        this.onInit(useMax, listDataset);
                    }, 500);
                }
            },
            onInit: function(useMax, listDataset) {
                var vx = this;
                if (vx.myBar != null){
                    vx.myBar.destroy()
                }
                var listLabel = this.data.map(x => x.label);
                var ctx = document.getElementById('kt_bar_chart').getContext('2d');
                var barChartData = {
                    labels: listLabel,
                    datasets: listDataset
                };
                var myBar = new Chart(ctx, {
                    type: 'bar',
                    data: barChartData,
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        legend: false,
                        scales: {
                        xAxes: [{
                                display: true,
                                scaleLabel: {
                                display: false,
                                labelString: 'Month'
                            },
                            gridLines: false,
                            ticks: {
                                display: true,
                                beginAtZero: true,
                                fontColor: KTApp.getBaseColor('shape', 3),
                                fontSize: 13,
                                padding: 10,
                                callback:  (value) => {
                                    return moment(value).format('DD MMM')
                                }
                            }
                        }],
                        yAxes: [{
                            display: true,
                            scaleLabel: {
                                display: false,
                                labelString: 'Value'
                            },
                            gridLines: {
                                color: '#eef2f9',
                                drawBorder: false,
                                offsetGridLines: true,
                                drawTicks: false,
                            },
                            ticks: {
                                max: useMax,
                                stepSize: useMax / 10,
                                display: true,
                                beginAtZero: true,
                                fontColor: KTApp.getBaseColor('shape', 3),
                                fontSize: 13,
                                padding: 10,
                                callback:  (value) => {
                                    return vx.convertSeperator(value);
                                }
                            }
                        }]
                        },
                        title: {
                            display: false
                        },
                        hover: {
                            mode: 'index'
                        },
                        elements: {
                            line: {
                                tension: 0.5
                            },
                            point: {
                                radius: 0
                            }
                        },
                        tooltips: {
                            enabled: true,
                            intersect: false,
                            mode: 'nearest',
                            bodySpacing: 5,
                            yPadding: 10,
                            xPadding: 10,
                            caretPadding: 0,
                            displayColors: false,
                            backgroundColor: KTApp.getStateColor('brand'),
                            titleFontColor: '#ffffff',
                            cornerRadius: 4,
                            footerSpacing: 0,
                            titleSpacing: 0,
                            callbacks: {
                                label: (tooltipItem, data) => {
                                    return vx.convertSeperator(tooltipItem.yLabel)
                                }
                            }

                        },
                        layout: {
                            padding: {
                                left: 0,
                                right: 0,
                                top: 5,
                                bottom: 5
                            }
                        }
                    }
                });
                vx.myBar = myBar;

            }
        },
        mounted () {
            this.getData();
        },
    }
</script>

<style>

</style>
