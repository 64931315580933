<template>
    <div class="row ml-2">
        <!-- Type Reservasi -->
        <div class="dropdown mr-3 mb-3 float-left">
            <button
                class="btn btn-outline-primary dropdown-toggle "
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                :title="showData(src.typeReservasi)"
            >
                Type Reservasi
                <div class="text-ellipsis" v-if="src.typeReservasi.length > 0">
                    : {{ src.typeReservasi[0] }}
                </div>
                <span
                    class="dots-btn"
                    v-if="src.typeReservasi.length > 0"
                ></span>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <label class="dropdown-item d-block pointer"
                    ><input
                        class="mt-1"
                        type="checkbox"
                        name="checkbox"
                        value="TELECONSULT"
                        v-model="src.typeReservasi"/>&nbsp; Telekonsul
                    <i class="fa  fa-headset float-right"></i
                ></label>
                <label class="dropdown-item d-block pointer"
                    ><input
                        class="mt-1"
                        type="checkbox"
                        name="checkbox"
                        value="NONTELECONSULT"
                        v-model="src.typeReservasi"/>&nbsp; Non Telekonsul
                    <i class="fa fa-user float-right"></i
                ></label>
            </div>
        </div>
        <!-- Status Bayar Telekonsul -->
        <div class="dropdown mr-3  mb-3 float-left">
            <button
                class="btn btn-outline-primary dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                :title="showData(src.statusBayar)"
            >
                Status Bayar Telekonsul
                <div class="text-ellipsis" v-if="src.statusBayar.length > 0">
                    : {{ src.statusBayar[0] }}
                </div>
                <span class="dots-btn" v-if="src.statusBayar.length > 0"></span>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <label class="dropdown-item d-block pointer"
                    ><input
                        type="checkbox"
                        name="checkbox"
                        value="PAID"
                        class="mt-1"
                        v-model="src.statusBayar"/>&nbsp; Sudah Bayar
                    <i class="fa fa-dollar-sign float-right"></i
                ></label>
                <label class="dropdown-item d-block pointer"
                    ><input
                        class="mt-1"
                        type="checkbox"
                        name="checkbox"
                        value="UNDERPAYMENT"
                        v-model="src.statusBayar"/>&nbsp; BELUM BAYAR
                    <i class="fa fa-dollar-sign text-danger float-right"></i
                ></label>
                <label class="dropdown-item d-block pointer"
                    ><input
                        class="mt-1"
                        type="checkbox"
                        name="checkbox"
                        value="EXPIRED"
                        v-model="src.statusBayar"/>&nbsp; Expired
                    <i class="fas fa-hourglass-end float-right"></i
                ></label>
            </div>
        </div>
        <!-- HIS -->
        <div class="dropdown mr-3 mb-3 float-left">
            <button
                class="btn btn-outline-primary dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                :title="showData(src.hisStatus)"
            >
                HIS
                <div class="text-ellipsis" v-if="src.hisStatus.length > 0">
                    : {{ src.hisStatus[0] == "true" ? "SUDAH" : "BELUM" }}
                </div>
                <span class="dots-btn" v-if="src.hisStatus.length > 0"></span>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <label class="dropdown-item d-block pointer"
                    ><input
                        class="mt-1"
                        type="checkbox"
                        name="his"
                        value="true"
                        v-model="src.hisStatus"/>&nbsp; Sudah Input
                    <i class="fa fa-check-square float-right"></i
                ></label>
                <label class="dropdown-item d-block pointer"
                    ><input
                        class="mt-1"
                        type="checkbox"
                        name="his"
                        value="false"
                        v-model="src.hisStatus"/>&nbsp; Belum Input
                    <i class="far fa-square text-danger float-right"></i
                ></label>
            </div>
        </div>
        <!-- Appointment Via -->
        <div class="dropdown mr-3 mb-3 float-left">
            <button
                class="btn btn-outline-primary dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                :title="showData(src.createdVia)"
            >
                Appointment Via
                <div class="text-ellipsis" v-if="src.createdVia.length > 0">
                    : {{ src.createdVia[0] }}
                </div>
                <span class="dots-btn" v-if="src.createdVia.length > 0"></span>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <label class="dropdown-item d-block pointer"
                    ><input
                        class="mt-1 pt-1"
                        type="checkbox"
                        name="checkbox"
                        value="BACKOFFICE"
                        v-model="src.createdVia"/>&nbsp;Back office
                    <i class="fa fa-credit-card float-right"></i
                ></label>
                <label class="dropdown-item d-block pointer"
                    ><input
                        class="mt-1"
                        type="checkbox"
                        name="checkbox"
                        value="MOBILE"
                        v-model="src.createdVia"/>&nbsp;Mobile
                    <i class="fa  fa-mobile-alt float-right"></i
                ></label>
            </div>
        </div>
        <!-- Slot defect -->
        <div class="dropdown mr-3 mb-3 float-left">
            <button
                class="btn btn-outline-primary dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                :title="showData(src.defect)"
            >
                Defect
                <div class="text-ellipsis" v-if="src.defect.length > 0">
                    : {{ src.defect[0] }}
                </div>
                <span class="dots-btn" v-if="src.defect.length > 0"></span>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <label class="dropdown-item d-block pointer"
                    ><input
                        class="mt-1 pt-1"
                        type="checkbox"
                        name="checkbox"
                        value="defect"
                        v-model="src.defect"/>&nbsp;DEFECT&nbsp;
                    <i class="fa fa-layer-group float-right"></i
                ></label>
                <label class="dropdown-item d-block pointer"
                    ><input
                        class="mt-1"
                        type="checkbox"
                        name="checkbox"
                        value="tidak"
                        v-model="src.defect"/>&nbsp;Tidak defect
                    <i class="fa  fa-stop float-right"></i
                ></label>
            </div>
        </div>
        <div class="dropdown mr-3 mb-3 float-left">
            <button
                class="btn btn-outline-primary dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                :title="changeSort(invalid)"
            >
                Booking :
                <div class="text-ellipsis">{{ changeSort(invalid) }}</div>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <label class="dropdown-item"
                    ><input
                        class="mt-1"
                        type="radio"
                        name="invalid-filter"
                        value="Invalid"
                        v-model="invalid"
                    />&nbsp; Invalid</label
                >
                <label class="dropdown-item"
                    ><input
                        class="mt-1"
                        type="radio"
                        name="invalid-filter"
                        value="Valid"
                        v-model="invalid"
                    />&nbsp;Valid</label
                >
                <label class="dropdown-item"
                    ><input
                        class="mt-1"
                        type="radio"
                        name="invalid-filter"
                        value="All"
                        v-model="invalid"
                    />&nbsp;All</label
                >
            </div>
        </div>
        <!-- Sort by  -->
        <div class="dropdown mr-3 mb-3 float-left">
            <button
                class="btn btn-outline-primary dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                :title="changeSort(sortingParams)"
            >
                Sort by :
                <div class="text-ellipsis">{{ changeSort(sortingParams) }}</div>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <label class="dropdown-item"
                    ><input
                        class="mt-1"
                        type="radio"
                        name="sort-param"
                        value="createdDate,desc"
                        v-model="sortingParams"
                    />&nbsp; tanggal dibuat terbaru</label
                >
                <label class="dropdown-item"
                    ><input
                        class="mt-1"
                        type="radio"
                        name="sort-param"
                        value="tanggalKunjungan,desc"
                        v-model="sortingParams"
                    />&nbsp;tanggal kunjungan terbaru</label
                >
                <label class="dropdown-item"
                    ><input
                        class="mt-1"
                        type="radio"
                        name="sort-param"
                        value="namaPasien,desc"
                        v-model="sortingParams"
                    />&nbsp;nama Z-A</label
                >
                <label class="dropdown-item"
                    ><input
                        class="mt-1"
                        type="radio"
                        name="sort-param"
                        value="namaPasien,asc"
                        v-model="sortingParams"
                    />&nbsp;nama A-Z</label
                >
            </div>
        </div>
    </div>
</template>

<script>
import SearchCallCenter from "./../../../model/search-call-center";
import { mapMutations } from "vuex";

export default {
    data() {
        return {
            sortingParams: "createdDate,desc",
            searchData: new SearchCallCenter(),
            typeReservasi: [],
            statusBayar: [],
            hisStatus: [],
            createdVia: [],
            invalid: "All",
            obj: {
                "typeReservasi.in": [],
                "statusBayarTeleconsult.in": [],
                "nomorRegistrasi.specified": [],
                "createdVia.in": []
            },
            src: {
                typeReservasi: [],
                statusBayar: [],
                hisStatus: ["false"],
                createdVia: [],
                defect: []
            }
        };
    },
    watch: {
        "src.typeReservasi"(val) {
            this.obj["typeReservasi.in"] = val.toString();
            this.$store.commit(
                "DoctorAppointmentStore/SET_CHECKBOX_PARAMS",
                this.obj
            );
            this.$root.$emit("searching");
            this.$store.dispatch(
                "DoctorAppointmentStore/GET_LIST_CALL_CENTER_BOOKING"
            );
        },
        "src.statusBayar"(val) {
            this.obj["statusBayarTeleconsult.in"] = val.toString();
            this.$store.commit(
                "DoctorAppointmentStore/SET_CHECKBOX_PARAMS",
                this.obj
            );
            this.$root.$emit("searching");
            this.$store.dispatch(
                "DoctorAppointmentStore/GET_LIST_CALL_CENTER_BOOKING"
            );
        },
        "src.hisStatus"(val) {
            this.obj["nomorRegistrasi.specified"] =
                val.length == 1 ? val.toString() : "";
            this.$store.commit(
                "DoctorAppointmentStore/SET_CHECKBOX_PARAMS",
                this.obj
            );
            this.$root.$emit("searching");
            this.$store.dispatch(
                "DoctorAppointmentStore/GET_LIST_CALL_CENTER_BOOKING"
            );
        },
        "src.createdVia"(val) {
            this.obj["createdVia.in"] = val.toString();
            this.$store.commit(
                "DoctorAppointmentStore/SET_CHECKBOX_PARAMS",
                this.obj
            );
            this.$root.$emit("searching");
            this.$store.dispatch(
                "DoctorAppointmentStore/GET_LIST_CALL_CENTER_BOOKING"
            );
        },
        sortingParams(val) {
            this.$store.commit(
                "DoctorAppointmentStore/SET_SORTING_PARAMS",
                val
            );
            this.$root.$emit("searching");
            this.$store.dispatch(
                "DoctorAppointmentStore/GET_LIST_CALL_CENTER_BOOKING"
            );
        },
        "src.defect"(val) {
            this.$store.commit("DoctorAppointmentStore/SET_PAGINATE", false);
            this.$store.commit(
                "DoctorAppointmentStore/SET_LIST_CALL_CENTER_BOOKING",
                []
            );
            if (val.length == 0 || val.length == 2) {
                this.$store.commit(
                    "DoctorAppointmentStore/SET_SLOT_DEFECT",
                    "multi"
                );
            } else {
                if (val[0] == "defect") {
                    this.$store.commit(
                        "DoctorAppointmentStore/SET_SLOT_DEFECT",
                        "defect"
                    );
                } else {
                    this.$store.commit(
                        "DoctorAppointmentStore/SET_SLOT_DEFECT",
                        "notDefect"
                    );
                }
            }
            this.$root.$emit("searching");
            this.$store.dispatch(
                "DoctorAppointmentStore/GET_LIST_CALL_CENTER_BOOKING"
            );
        },
        invalid(val) {
            this.setInvBooking(val);
        }
    },
    methods: {
        ...mapMutations({
            setInvBooking: "DoctorAppointmentStore/SET_INVALID_BOOKING"
        }),
        showData(item) {
            if (item.length == 0) return "";
            let data = "";
            for (let i = 0; i <= item.length - 2; i++) {
                data += item[i] + ", ";
            }
            data += item[item.length - 1];
            return data;
        },
        turnString(key, val) {
            let str = "";
            const map1 = val.map((x, i) => {
                if (i == 0) {
                    str += x;
                } else {
                    str += `&${key}=${x}`;
                }
            });
            return str;
        },
        changeSort(item) {
            if (item == "createdDate,desc") {
                return "Tanggal dibuat terbaru";
            } else if (item == "tanggalKunjungan,desc") {
                return "Tanggal kunjungan terbaru";
            } else if (item == "namaPasien,desc") {
                return "Nama Pasien Z-A";
            } else if (item == "namaPasien,asc") {
                return "Nama Pasien A-Z";
            } else if (item == "Invalid") {
                return "Invalid";
            } else if (item == "Valid") {
                return "Valid";
            } else if (item == "All") {
                return "All";
            }
        }
    }
};
</script>
