<template>
    <div class="kt-portlet kt-portlet--height-fluid">
        <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title">Custom Poin</h3>
            </div>
            <div class="kt-portlet__head-toolbar"></div>
        </div>
        <div class="kt-portlet__body">
            <div class="row mb-3">
                <div class="col-6">
                    <year-select
                        :changedValue.sync="year"
                        :id-select-picker="'selectYearCp'"
                        :selected-picker="year"
                        :is-validate="true"
                    ></year-select>
                </div>
                <div class="col-6">
                    <month-select
                        :changedValue.sync="month"
                        :id-select-picker="'selectMonthCp'"
                        :selected-picker="month"
                        :value-num="true"
                    ></month-select>
                </div>
            </div>
            <div class="kt-widget-4 ps-scroll-cp kt-scroll" data-scroll="true"  style="height:300px">
                <div v-if="!isLoading">
                    <div v-if="data.length > 0">
                        <div class="kt-widget-4__item" v-for="(value,index) in data" :key="index">
                            <div class="kt-widget-4__item-content">
                                <div class="kt-widget-4__item-section">
                                    <div class="kt-widget-4__item-pic">
                                    <span class="kt-badge kt-badge--xl kt-badge--accent" :class="[value.colorBadge]">{{  value.title | badge(value.subtitle) }} </span>
                                    </div>
                                    <div class="kt-widget-4__item-info">
                                        <h6 class="kt-font-bolder mb-0">{{value.title}}</h6>
                                        <small class="text-muted">{{value.subtitle}}</small>
                                    </div>
                                </div>
                            </div>
                            <div class="kt-widget-4__item-content mr-3">
                                <div class="kt-widget-4__item-price">
                                    <span class="kt-font-bolder">{{value.point}} poin</span><br/>
                                    <small class="text-muted">{{value.type_custom}} - {{value.theme_custom}}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="d-flex justify-content-center">
                        <div class="kt-iconbox">
                            <div class="kt-iconbox__icon">
                                <div class="kt-iconbox__icon-bg"></div>
                                <i class="flaticon-graph"></i>
                            </div>
                            <div class="kt-iconbox__title">
                                Data tidak ditemukan.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center" v-else>
                    <div class="spinner-border">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import MonthSelect from "./../../../components/_select/Month";
// import YearSelect from "./../../../components/_select/Year";
import YearSelect from "./../base/YearSelect";
import Pallate from "./../../../constants/color-palate";
import { RepositoryFactory } from "./../../repositories/BaseRepositoryFactory";
const DashboardRepository = RepositoryFactory.get("dashboardRepository");
var parse = require('parse-link-header');
export default {
    components: {
        MonthSelect,
        YearSelect
    },
    data() {
        return {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            data: [],
            isLoading: true,
            colorBadge: Pallate.badge(),
        }
    },
    watch: {
        year(val){
            this.fetch();
        },
        month(val){
            this.fetch();
        }
    },
    filters: {
        // fullName: function(firstName, lastName) {
        //     var fName = (firstName) ? firstName : '';
        //     var lName = (lastName) ? lastName : '';
        //     return fName+ ' '+lName;
        // },
        badge: function(firstName, lastName) {
            var fName = (firstName) ? firstName[0] : '';
            var lName = (lastName) ? lastName[0] : '';
            return fName+lName;
        },
        convertSeperator: function(angka) {
            if (angka !== null) {
                const parts = angka.toString().split('.');
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                return parts.join(',');
            }
            return angka;
        },
    },
    methods: {
        getBadge: function(){
             return this.colorBadge[Math.floor(Math.random() * this.colorBadge.length)];
        },
        fetch: function() {
            this.isLoading = true;
            // var startDate = moment(this.year + '-' + this.month + '-01').format('YYYY-MM-DD');
            // var endDate = moment(startDate).endOf('month').format('YYYY-MM-DD');
            var params = {
                year: this.year,
                month: this.month
            };
            new Promise((resolve, reject) => {
                DashboardRepository.customPoint(params, resolve, reject )
            })
            .then(response => {
                let temp = [];
                response.data.forEach(x => {
                    x.colorBadge = this.getBadge();
                    temp.push(x);
                });
                this.data = temp;
                this.isLoading = false;
            }).catch(error => {
                var msg = (error.message) ? error.message : error;
                toastr.error('Custom Point: ' + msg);
            })
        }
    },
    mounted () {
        var vx = this;
        var container = document.querySelector('.ps-scroll-cp');
        const ps1 = new PerfectScrollbar(container, {minScrollbarLength: false});
        this.fetch();
    },
}
</script>

<style>

</style>
